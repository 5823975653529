
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { UserManager, WebStorageStateStore, User } from "oidc-client-ts";
import ApiService from "@/core/services/ApiService";
import JwtService, { saveOrganization, saveRehab, saveUser } from "@/core/services/JwtService";

export default defineComponent({
  name: "sign-in",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const userManager = getManager();
    onMounted(async () => {
      window.location.search = decodeURIComponent(window.location.hash).replace(
        "#/callback?",
        ""
      );
      await nextTick();
      if (userManager) {
        await check();
      }
    });

    function getManager() {
      let odicClientSettings = {} as any;
      odicClientSettings.authority =
        "https://alleva-identity-int.helloalleva.com/";
      odicClientSettings.client_id = "alleva.billing";
      odicClientSettings.redirect_uri =
        "http://billing.allevasoft.local:8080/#/authcallback";
      odicClientSettings.post_logout_redirect_uri =
        "http://localhost:8080/#/authcallback";
      odicClientSettings.response_type = "code";
      odicClientSettings.response_mode = "query";
      odicClientSettings.scope = "openid profile";
      odicClientSettings.filterProtocolClaims = true;
      odicClientSettings.loadUserInfo = true;
      odicClientSettings.getclaimsfromuserinfoendpoint = true;
      odicClientSettings.automaticSilentRenew = false;
      let manager = new UserManager(odicClientSettings);
      return manager;
    }

    function callAuth() {
      let manager = getManager();
      store.commit(Mutations.SET_USER_MANAGER, manager);
      manager.signinRedirect();
    }

    async function check() {
      var user = await userManager.signinRedirectCallback();
      if (user) {
        store.commit(Mutations.SET_AUTH, user);
        saveUser(user);
        ApiService.setHeader();
        await getOrganizations();
      }
    }

    async function getOrganizations(){
      await store.dispatch("GETORGANIZATIONS");
      // set default org
      const orgList = store.getters.allOrganizations;
      saveOrganization(orgList[0].id);
      store.commit("SETORGANIZATION", orgList[0]);
      ApiService.setTenant();
      // and rehab
      await store.dispatch("GETALLREHABS");
      const rehabs = store.getters.allRehabs;
      if (rehabs.length > 0) {
        saveRehab(rehabs[0].rehabId);
        store.commit("SETCURRENTREHAB", rehabs[0]);
        ApiService.setRehab();
      }
    }

    function clean() {
      store.commit(Mutations.PURGE_AUTH);
      saveUser(null);
      ApiService.setUserHeader(null);
      ApiService.setHeader();
    }

    async function testAuth() {
      await ApiService.get("api/DemoAuth/TestAuth");
    }

    //Form submit function

    return { callAuth, check, testAuth, clean };
  },
});
