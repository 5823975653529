import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.callAuth())),
      class: "btn"
    }, "Auth"),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.check())),
      class: "btn"
    }, "Check"),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.testAuth())),
      class: "btn"
    }, "Test"),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clean())),
      class: "btn"
    }, "Clean")
  ]))
}